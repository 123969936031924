import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import App from './App';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  const root = ReactDOM.hydrateRoot(document.getElementById("root"), <App />);
  console.log(root);
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
}
